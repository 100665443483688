import React, { FC } from "react";
import cx from "classnames";

interface Props {
  className?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  style?: React.CSSProperties;
}

const NextButton: FC<Props> = ({ className, onClick, style }) => {
  return (
    <button
      className={cx(
        "bg-white py-3 pl-0.6 pr-0.4 focus:outline-none ring-0",
        className
      )}
      onClick={onClick}
      style={style}
    >
      <svg
        width="40px"
        height="38px"
        viewBox="0 0 40 38"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <polygon
          id="Fill-1"
          fill="#000000"
          points="20.6761146 0 40 18.9992721 20.6761146 38 18.3097136 35.6663985 33.5208149 20.6661303 0 20.6661303 0 17.3324139 33.5208149 17.3324139 18.3097136 2.33214573"
        ></polygon>
      </svg>
    </button>
  );
};

export default NextButton;

import React, { FC } from "react";

import Icon from "images/spotify.svg";

const SpotifyButton: FC<{ href: string }> = ({ href }) => (
  <div className="mt-1">
    <a
      href={href}
      target="_blank"
      rel="noreferrer noopener"
      style={{ height: "5rem", width: "20.6rem", display: "block" }}
    >
      <img className="w-full h-auto block" src={Icon} />
    </a>
  </div>
);

export default SpotifyButton;

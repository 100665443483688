import React, { FC, useEffect, useMemo, useRef, useState } from "react";
// import cx from "classnames";

interface Props {
  audioFile?: string;
}

const AudioPlayer: FC<Props> = ({ audioFile }) => {
  const playerRef = useRef<HTMLAudioElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const progress = duration ? currentTime / duration : 0;

  const radius = 74;
  const circumfrence = Math.PI * (radius * 2);
  const strokeDashoffset = useMemo(() => {
    let p = progress;
    if (p < 0) p = 0;
    if (p > 100) p = 100;
    return (1 - p) * circumfrence;
  }, [progress]);

  const handleButtonClick = (evt: React.MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault();
    if (!playerRef.current) return;
    if (playerRef.current.paused) {
      setIsPlaying(true);
      playerRef.current.play();
    } else {
      setIsPlaying(false);
      playerRef.current.pause();
    }
  };

  const handleTimeUpdate = () => {
    if (!playerRef.current) return;
    setCurrentTime(playerRef.current.currentTime);
    setDuration(playerRef.current.duration);
  };

  // const handleTrackClick = (evt) => {
  //   evt.preventDefault();
  //   const rect = evt.target.getBoundingClientRect();
  //   const progress = (evt.pageX - rect.left) / rect.width;
  //   playerRef.current.currentTime = duration * progress;
  // };

  useEffect(() => {
    const currentRef = playerRef.current;
    if (!currentRef) return;
    currentRef.addEventListener("timeupdate", handleTimeUpdate);
    return () => {
      currentRef.removeEventListener("timeupdate", handleTimeUpdate);
    };
  }, [playerRef]);

  useEffect(() => {
    setIsPlaying(false);
    setCurrentTime(0);
    if (!playerRef.current) return;
    playerRef.current.pause();
    playerRef.current.currentTime = 0;
  }, [audioFile]);

  return (
    <>
      <audio ref={playerRef} src={audioFile} preload="auto" />
      <button
        className="absolute focus:outline-none w-10 h-10 md:w-16 md:h-16 -mt-6 md:mt-0"
        onClick={handleButtonClick}
        style={{
          left: "50%",
          top: "50%",
          transform: "translate3d(-50%, -82%, 0)",
        }}
      >
        <svg
          className="w-full h-full block align-top"
          width="159px"
          height="158px"
          viewBox="0 0 159 158"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <circle
            r={radius}
            cx="77.5"
            cy="79"
            fill="transparent"
            stroke="#FEFEFE"
            strokeWidth="1.5px"
            strokeDasharray={circumfrence}
            strokeDashoffset="0"
          ></circle>
          <circle
            r={radius}
            cx="77.5"
            cy="79"
            fill="transparent"
            stroke="#FEFEFE"
            strokeWidth="6px"
            strokeDasharray={circumfrence}
            strokeDashoffset={strokeDashoffset}
            style={{
              transform: "rotate(-90deg)",
              transformOrigin: "77.5px 79px",
            }}
          ></circle>
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            {isPlaying ? (
              <path
                d="M77.5433962,15 C113.190189,15 142.086792,43.6265421 142.086792,78.9401869 C142.086792,114.253832 113.190189,142.881869 77.5433962,142.881869 C41.8966038,142.881869 13,114.253832 13,78.9401869 C13,43.6265421 41.8966038,15 77.5433962,15 Z M71,59 L63,59 C61.8954305,59 61,59.8954305 61,61 L61,61 L61,97 C61,98.1045695 61.8954305,99 63,99 L63,99 L71,99 C72.1045695,99 73,98.1045695 73,97 L73,97 L73,61 C73,59.8954305 72.1045695,59 71,59 L71,59 Z M93,59 L85,59 C83.8954305,59 83,59.8954305 83,61 L83,61 L83,97 C83,98.1045695 83.8954305,99 85,99 L85,99 L93,99 C94.1045695,99 95,98.1045695 95,97 L95,97 L95,61 C95,59.8954305 94.1045695,59 93,59 L93,59 Z"
                fill="#FEFEFE"
              ></path>
            ) : (
              <path
                d="M98.9456604,79.9390654 C98.7690566,80.2441121 98.5139623,80.4968224 98.2075472,80.6702804 L66.9637736,98.5409346 C65.9992453,99.0927103 64.7645283,98.7652336 64.2090566,97.8097196 C64.0324528,97.5061682 63.9388679,97.1607477 63.9388679,96.8108411 L63.9388679,61.071028 C63.9388679,59.9674766 64.8415094,59.0717757 65.9554717,59.0717757 C66.3101887,59.0717757 66.6573585,59.164486 66.9637736,59.3394393 L98.2075472,77.2100935 C99.1720755,77.7618692 99.5026415,78.9850467 98.9456604,79.9390654 M77.5433962,15 C41.8966038,15 13,43.6265421 13,78.9401869 C13,114.253832 41.8966038,142.881869 77.5433962,142.881869 C113.190189,142.881869 142.086792,114.253832 142.086792,78.9401869 C142.086792,43.6265421 113.190189,15 77.5433962,15"
                fill="#FEFEFE"
              ></path>
            )}
          </g>
        </svg>
      </button>
    </>
  );
};

export default AudioPlayer;

import React, { FC, useCallback, useMemo, useState } from "react";
import GatsbyImage from "gatsby-image";
import cx from "classnames";
import { graphql } from "gatsby";
import { useSwipeable } from "react-swipeable";

import { HomePageQuery } from "lib/cms";

import NotFoundPage from "./404";
import NextButton from "components/NextButton";
import PrevButton from "components/PrevButton";
import MetaData from "components/MetaData";
import AudioPlayer from "components/AudioPlayer";
import MaxWidth from "components/MaxWidth";
import PortableText from "components/PortableText";
import AppleButton from "components/AppleButton";
import SpotifyButton from "components/SpotifyButton";

interface Props {
  data: HomePageQuery;
}

const HomePage: FC<Props> = ({ data: { page } }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [readMoreIsOpen, setReadMoreIsOpen] = useState(false);

  const goPrev = useCallback(() => {
    let prevIndex = activeIndex - 1;
    if (prevIndex < 0) prevIndex = page.slides.length - 1;
    setActiveIndex(prevIndex);
  }, [activeIndex, setActiveIndex]);

  const goNext = useCallback(() => {
    let nextIndex = activeIndex + 1;
    if (nextIndex > page.slides.length - 1) nextIndex = 0;
    setActiveIndex(nextIndex);
  }, [activeIndex, setActiveIndex]);

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => {
      goNext();
    },
    onSwipedRight: () => {
      goPrev();
    },
  });

  const activeAudio: string | undefined = useMemo(() => {
    return page.slides[activeIndex].audio?.asset.url;
  }, [activeIndex, page.slides]);

  return page ? (
    <>
      <MetaData />
      <div className="h-5.6 flex-shrink-0 md:hidden"></div>
      <div className="relative flex-grow">
        <div {...swipeHandlers} className="absolute inset-0 overflow-hidden">
          {page.slides.map((slide, index) => (
            <div
              key={slide._key}
              className={cx("absolute inset-0 flex flex-col", {
                "opacity-1 visible": activeIndex === index,
                "opacity-0 invisible": activeIndex !== index,
              })}
              style={{ transition: "all .5s ease-out" }}
            >
              <div
                className={cx("relative", {
                  "flex-grow-0": readMoreIsOpen,
                  "flex-grow": !readMoreIsOpen,
                })}
                style={{ transition: "flex .5s ease-out" }}
              >
                <GatsbyImage
                  fluid={slide.coverImage.asset.fluid}
                  imgStyle={{
                    bottom: 0,
                    height: "100%",
                    left: 0,
                    objectFit: "cover",
                    objectPosition: "center",
                    position: "absolute",
                    right: 0,
                    top: 0,
                    width: "100%",
                  }}
                  style={{
                    bottom: 0,
                    left: 0,
                    position: "absolute",
                    right: 0,
                    top: 0,
                  }}
                />
              </div>
              <header
                className="text-medium md:text-base flex flex-col md:flex-row justify-between w-100"
                style={{
                  backgroundColor: slide.secondaryColor,
                  color: slide.primaryColor,
                }}
              >
                <hgroup className="flex-grow flex flex-col md:flex-row justify-around">
                  <h1 className="px-2 md:px-1.6 md:pb-1.8 truncate uppercase">
                    {slide.podcast.name}
                  </h1>
                  <h2 className="px-2 md:px-1.6 md:pb-1.8">
                    Ep. {slide.episode.number}
                  </h2>
                  <h3 className="px-2 md:px-1.6 pb-1 md:pb-1.8 truncate uppercase">
                    {slide.episode.title}
                  </h3>
                </hgroup>
                <button
                  className="bg-black text-white px-2 md:px-1.6 pb-1 md:pb-1.8 text-left md:text-center whitespace-nowrap focus:outline-none w-full md:w-32"
                  onClick={evt => {
                    evt.preventDefault();
                    setReadMoreIsOpen(state => !state);
                  }}
                >
                  {readMoreIsOpen ? "Close" : "Read more"}
                </button>
              </header>
              <div
                className={cx("relative overflow-hidden", {
                  "flex-grow-0": !readMoreIsOpen,
                  "flex-grow": readMoreIsOpen,
                })}
                style={{
                  background: slide.primaryColor,
                  color: slide.secondaryColor,
                  transition: "flex .5s ease-out",
                }}
              >
                <div className="absolute inset-0 overflow-y-auto">
                  <MaxWidth>
                    <div className="flex flex-col md:flex-row mt-4 md:mt-0 mb-6 md:mb-18 items-center md:items-end">
                      <div className="flex-shrink-0 h-23 w-23 md:h-56 md:w-56 md:mr-9">
                        <GatsbyImage
                          fluid={
                            slide.episode.thumbnail?.asset.fluid ||
                            slide.podcast.thumbnail.asset.fluid
                          }
                        />
                      </div>
                      <div className="md:pb-4 w-full md:w-auto flex flex-col-reverse md:flex-col items-center md:items-start">
                        <hgroup className="text-medium md:text-base w-full mt-6 md:mt-0">
                          <h3>Episode #{slide.episode.number}</h3>
                          <h2 className="font-bold">{slide.episode.title}</h2>
                        </hgroup>
                        {(slide.episode.appleLink ||
                          slide.episode.spotifyLink) && (
                          <div className="mt-2 md:mt-6">
                            {slide.episode.appleLink && (
                              <AppleButton href={slide.episode.appleLink} />
                            )}
                            {slide.episode.spotifyLink && (
                              <SpotifyButton href={slide.episode.spotifyLink} />
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                    <PortableText
                      blocks={slide.episode.description}
                      className="text-medium md:text-base"
                      indent={false}
                    />
                  </MaxWidth>
                </div>
              </div>
            </div>
          ))}
          <PrevButton
            className="absolute hidden md:block"
            onClick={evt => {
              evt.preventDefault();
              goPrev();
            }}
            style={{
              left: 0,
              bottom: "50%",
              opacity: readMoreIsOpen ? 0 : 1,
              transform: readMoreIsOpen ? "translateX(-100%)" : "translateX(0)",
              transition: readMoreIsOpen
                ? "all .2s ease-out"
                : "all .2s ease-out .3s",
            }}
          />
          <NextButton
            className="absolute hidden md:block"
            onClick={evt => {
              evt.preventDefault();
              goNext();
            }}
            style={{
              right: 0,
              bottom: "50%",
              opacity: readMoreIsOpen ? 0 : 1,
              transform: readMoreIsOpen ? "translateX(100%)" : "translateX(0)",
              transition: readMoreIsOpen
                ? "all .2s ease-out"
                : "all .2s ease-out .3s",
            }}
          />
          <div className="absolute bottom-23 w-full md:hidden">
            <ul className="flex w-full justify-center">
              {page.slides.map((slide, index) => (
                <li
                  className={cx(
                    "bg-white w-1.4 h-1.4 mx-0.6 rounded-full opacity-50",
                    { "opacity-100": index === activeIndex }
                  )}
                  key={slide._key}
                  onClick={evt => {
                    evt.preventDefault();
                    setActiveIndex(index);
                  }}
                ></li>
              ))}
            </ul>
          </div>
          <div
            className={cx({
              "opacity-1 visibile": !readMoreIsOpen,
              "opacity-0 invisible": readMoreIsOpen,
            })}
            style={{
              transition: readMoreIsOpen
                ? "all .2s ease-out"
                : "all .2s ease-out .3s",
            }}
          >
            <AudioPlayer audioFile={activeAudio} />
          </div>
        </div>
      </div>
    </>
  ) : (
    <NotFoundPage />
  );
};

export const query = graphql`
  query Home {
    page: sanityHome {
      slides {
        _key
        podcast {
          _id
          name
          thumbnail {
            asset {
              fluid(maxWidth: 1200) {
                ...GatsbySanityImageFluid_withWebp
              }
            }
          }
        }
        episode {
          _key
          title
          number
          appleLink
          spotifyLink
          thumbnail {
            asset {
              _id
              fluid(maxWidth: 1200) {
                ...GatsbySanityImageFluid_withWebp
              }
            }
          }
          description: _rawDescription
        }
        audio {
          asset {
            url
          }
        }
        coverImage {
          asset {
            _id
            fluid(maxWidth: 3000) {
              ...GatsbySanityImageFluid_withWebp
            }
          }
        }
        primaryColor
        secondaryColor
      }
    }
  }
`;

export default HomePage;

import React, { FC } from "react";
import cx from "classnames";

interface Props {
  className?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  style?: React.CSSProperties;
}

const PrevButton: FC<Props> = ({ className, onClick, style }) => {
  return (
    <button
      className={cx(
        "bg-white py-3 pl-0.4 pr-0.6 focus:outline-none ring-0",
        className
      )}
      onClick={onClick}
      style={style}
    >
      <svg
        width="40px"
        height="38px"
        viewBox="0 0 40 38"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <polygon
          id="Fill-1"
          fill="#000000"
          points="19.3238854 0 0 18.9992721 19.3238854 38 21.6902864 35.6663985 6.47918512 20.6661303 40 20.6661303 40 17.3324139 6.47918512 17.3324139 21.6902864 2.33214573"
        ></polygon>
      </svg>
    </button>
  );
};

export default PrevButton;
